<template>
  <slider-component
      loop
      play="6000"
      class="home-slider"
  >
    <slide-component
        v-for="(slide, index) in slides"
        :key="index"
        v-bind="slide"
        :eager="!index"
        class="slide"
        :cover="settings?.isCover"
    />
  </slider-component>
</template>

<script>

import SliderComponent from "../../components/slider/slider-component.vue";
import SlideComponent from "../../components/slider/slide-component.vue";

export default {
  name: "home-slider",
  components: {
    SlideComponent,
    SliderComponent
  },
  props: {
    settings: {
      type: Object,
      default: {}
    },
    slides: {
      type: Array,
      default: [],
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>