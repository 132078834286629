<template>
  <i-image
      :cover="cover"
      :src="size"/>
</template>

<script>

import IImage from "../base/i-image.vue";

export default {
  name: "slide-component",
  inheritAttrs: false,
  components: {
    IImage,
  },
  inject: ['slider'],
  props: {
    cover: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    md: {
      type: String,
      default: '',
    },
    xs: {
      type: String,
      default: '',
    },
    eager: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      activeKey: 'md'
    };
  },
  methods: {
    onClick(event) {
      this.slider.onClick(event, 'click');
    },
  },
  computed: {
    size() {
      return this[this.activeKey] ?? this.md;
    },
  },
  watch: {
    '$breakpoints.name'(val) {
      this.activeKey = val;
    }
  },
  mounted() {
    console.log(this.$props);
    this.slider.attach(this);
  },
  beforeDestroy() {
    this.slider.detach(this);
  },
}
</script>
