import '@fancyapps/ui';

import {Fancybox} from "@fancyapps/ui";

import './vue/index';

import './include/jquery-code';
import './include/init-maskedinput';
import './include/init-sliders';
import './include/init-datepicker';
import './include/init-modalSubscribe.js';
import $ from 'jquery';
import 'bootstrap'; // Подключил bootstrap модалка не работала
import * as Cookies from 'es-cookie';

window.$ = window.jQuery = $;
window.$ = window.jQuery = $.noConflict();
window.fbox = Fancybox;
window.cookie = Cookies;
Fancybox.bind("[data-fancybox]", {
    tpl: {
        closeButton: false,
    },
    on: {
        done: (fancybox, eventName) => {
            new initMask();
            new FormsAjax();
        },
    },
});

import {CraftForms} from "./block/craftForms";
import {initMask} from "./include/init-maskedinput";

import {FormsAjax} from "./block/formsAjax";

new CraftForms('.js-craft-form');


window.craftForm = CraftForms;
window.fbox = Fancybox;


// Это скорее всего не сюда
function movingNav(selector) {
    const container = document.querySelector(selector);
    const target = container.querySelector(".target-item");
    const links = container.querySelectorAll("a");

    function mouseenterFunc() {
        if (window.innerWidth > 992) {
            if (!this.parentElement.classList.contains("active")) {
                for (let i = 0; i < links.length; i++) {
                    if (links[i].parentElement.classList.contains("active")) {
                        links[i].parentElement.classList.remove("active");
                    }
                }

                this.parentElement.classList.add("active");

                const width = this.getBoundingClientRect().width;
                const height = this.getBoundingClientRect().height;
                const left = this.getBoundingClientRect().left + window.pageXOffset;
                const top = this.getBoundingClientRect().top + window.pageYOffset;

                target.style.width = `${width}px`;
                target.style.height = `${height}px`;
                target.style.left = `${left}px`;
                target.style.top = `${top}px`;
                target.style.transform = "none";
                target.style.display = "block";
            }
        } else {
            target.style.display = "none";
        }
    }

    for (let i = 0; i < links.length; i++) {
        links[i].addEventListener("mouseenter", mouseenterFunc);
    }

    function resizeFunc() {
        if (window.innerWidth < 992) {
            target.style.display = "none";
            return false;
        } else {
            target.style.display = "block";
        }
        const active = container.querySelector("li.active");

        if (active) {
            const left = active.getBoundingClientRect().left + window.pageXOffset;
            const top = active.getBoundingClientRect().top + window.pageYOffset;

            target.style.left = `${left}px`;
            target.style.top = `${top}px`;
        }
    }

    window.addEventListener("resize", resizeFunc);
};

movingNav('.header-menu');
$('.hamburger').on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    $this.toggleClass('active');
    if ($this.hasClass('hamburger--mobile')) {
        $('.overlay').toggleClass('active');
        $('.header-menu').toggleClass('open');
        $('.document-container').toggleClass('overflowed');
    }
});

$('.site-search--mobile .close').on('click', function (e) {
    e.preventDefault();
    $('.site-search--mobile').addClass('d-none');
});

$('.open-search').on('click', function (e) {
    e.preventDefault();
    $('.site-search--mobile').removeClass('d-none');
});


$(document).ready(function () {
    // Тут попытка c Fancybox
    /*    $(".show_modal").click(function () {
            Fancybox.show([
                {
                    src: "#modalSubscribe",
                    type: "inline",
                    opts: {
                        infobar: false,
                        clickOutside: true, // Разрешить закрытие при клике вне модального окна
                        touch: true, // З
                        trapFocus: false, // Запретить фокусировку на элементах за пределами модального окна
                    },
                },
            ]);
        });*/






/*    $('.callback-modal-js').on('click', function(event) {
        event.preventDefault();

        let url = '/ajax/modal_subscribe.php';
        let attributes = $(this).attr(["data-fancybox", "data-type"]);

        if (typeof attributes === "string") {
            attributes = attributes.toLowerCase();
        }

        $('.callback-modal-js').load(url, attributes);
    });*/


// ajax форма в футоре
    $('.ajax_js_form').submit(function (e) {
        e.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму
        var email = $('#subscribe-email').val();
        if (email){
            $.ajax({
                url: $('.ajax_js_form').attr('action'), // Получаем URL из атрибута action формы
                //dataType: 'json',
                data: formData,
                type: "POST",
                processData: false,
                contentType: false,

                success: function (response) {

                    $('#succers_alert').modal('show');
                    $('.ajax_js_form')[0].reset();
                    $('#succers_alert').modal('show');
                    setTimeout(function() {
                        $('#succers_alert').modal('hide');
                    }, 2000); // 2000 миллисекунд = 2 секунды
                }
            });

        }
        else {
            console.log('Данных нет');
        }

    });

    $('.ajax_js_reviews').submit(function (e) {
        e.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму

        $.ajax({
            url: $('.ajax_js_reviews').attr('action'), // Получаем URL из атрибута action формы
            //dataType: 'json',
            data: formData,
            type: "POST",
            processData: false,
            contentType: false,

            success: function (response) {
                $('.ajax_js_reviews')[0].reset();
                $('#succers_alert').modal('show');
                setTimeout(function() {
                    $('#succers_alert').modal('hide');
                }, 2000); // 2000 миллисекунд = 2 секунды
                console.log('Отправлено');
            }
        });
    });


// ajax модалка обратная связь

    /*$('.ajax_js_form_callback').submit(function (event) {
        event.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму

        var nameField = $('#feedback-name');
        var phoneField = $('#feedback-phone');

        // Проверка имени
        if (nameField.val() === '') {
            nameField.parent().addClass('has-error');
        } else {
            nameField.parent().removeClass('has-error');
        }

        // Проверка телефона
        if (phoneField.val() === '') {
            phoneField.parent().addClass('has-error');
        } else {
            phoneField.parent().removeClass('has-error');
        }

        if (!nameField.parent().hasClass('has-error') && !phoneField.parent().hasClass('has-error')) {
            $.ajax({
                url: $('.ajax_js_form_callback').attr('action'), // Получаем URL из атрибута action формы
                //dataType: 'json',
                data: formData,
                type: "POST",
                processData: false,
                contentType: false,

                success: function (response) {
                    $('.ajax_js_form_callback')[0].reset();
                    $('#callback').modal('hide');
                }
            });
        }
    });*/


    /*$('.ajax_js_form_subscribe').submit(function (event) {
        event.preventDefault();

        var form = $(this)[0]; // Получаем объект формы
        var formData = new FormData(form); // Создаем объект FormData и передаем форму
        var emailField = $('#subscribe-email-modal');
        // Проверка введен ли email
        if (emailField.val() === '') {
            emailField.parent().addClass('has-error');
        } else {
            emailField.parent().removeClass('has-error');
        }
        if (!emailField.parent().hasClass('has-error')) {
            $.ajax({
                url: $('.ajax_js_form_subscribe').attr('action'), // Получаем URL из атрибута action формы
                //dataType: 'json',
                data: formData,
                type: "POST",
                processData: false,
                contentType: false,

                success: function (response) {
                    $('.ajax_js_form_subscribe')[0].reset();
                    $('#modalSubscribe').modal('hide');
                }
            });
        }
    });*/

    $(".nav li a").click(function (event) {
        // Предотвращаем стандартное действие ссылки
        event.preventDefault();

        // Убираем класс 'active' у всех элементов
        $(".nav li a").removeClass('active');

        // Добавляем класс 'active' только текущему элементу
        $(this).addClass('active');

        // Дополнительный код, который вы хотите выполнить при клике
        var hrefValue = $(this).attr('href');
        if (hrefValue === "#type-0") {
            $("#type-1").removeClass('show active');
            $("#type-0").addClass('show active');
        } else {
            $("#type-0").removeClass('show active');
            $("#type-1").addClass('show active');
        }
    });


    $(".dropdown-toggle").click(function(){
        $(".dropdown-menu").toggleClass("show");
    });


});

window.imageZoom = (imgContainer) => {
    var lens, result, cx, cy;

    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    result = document.querySelector('.img-zoom-result');

    document.querySelectorAll(imgContainer).forEach((item) => {
        item.addEventListener('mouseenter', (event) => {
            result.style.display = "block";
            let img = item.querySelector('img');
            img.parentElement.insertBefore(lens, img);
            cx = result.offsetWidth / lens.offsetWidth;
            cy = result.offsetHeight / lens.offsetHeight;
            result.style.backgroundImage = "url('" + img.src + "')";
            result.style.backgroundSize = (img.clientWidth * cx) + "px " + (img.clientHeight * cy) + "px";
            lens.addEventListener("mousemove", (event) => {moveLens(event, img, item)});
            item.addEventListener("mousemove", (event) => {moveLens(event, img, item)});
            lens.addEventListener("touchmove", (event) => {moveLens(event, img, item)});
            item.addEventListener("touchmove", (event) => {moveLens(event, img, item)});
        });

        item.addEventListener('mouseleave',  (event)=>{
            lens.remove();
            result.style.display = "none";
        });
    });

    function moveLens(e, i, item) {
        e.preventDefault();
        var pos, x, y;
        pos = getCursorPos(e, i);
        x = pos.x - (lens.offsetWidth / 2);
        y = pos.y - (lens.offsetHeight / 2);
        if (x > i.clientWidth - lens.offsetWidth) {x = i.clientWidth - lens.offsetWidth;}
        if (x < 0) {x = 0;}
        if (y > i.clientHeight - lens.offsetHeight) {y = i.clientHeight - lens.offsetHeight;}
        if (y < 0) {y = 0;}
        lens.style.left = x - ((i.clientWidth - item.clientWidth) / 2) + "px";
        lens.style.top = y - ((i.clientHeight - item.clientHeight) / 2) + "px";
        result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }

    function getCursorPos(e, i) {
        var a, x = 0, y = 0;
        e = e || window.event;
        a = i.getBoundingClientRect();
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return {x: x, y: y};
    }
};

// Initialize image zoom
imageZoom('.img-popup');


$(document).on('click', '.set-rating .np-star', function (e) {
    $('.set-rating .np-star').addClass('no-select');
    let prev = $(this).prevAll();
    prev.removeClass('no-select');
    $(this).removeClass('no-select');
    $('#commentform-rating').val(prev.length+1);
});



