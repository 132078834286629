<template>
  <picture>
    <source v-if="webp" :srcset="webp" type="image/webp">
    <img :src="src" :style="style" :loading="eager ? 'eager' : 'lazy'" :alt="alt || ''">
  </picture>
</template>

<script>
export default {
  name: "i-image",
  props: {
    src: {
      type: String,
      default: null,
      required: true,
    },
    alt: {
      type: String,
      default: null,
    },
    width: {
      types: [null, Number],
      default: null,
    },
    height: {
      types: [null, Number],
      default: null,
    },
    aspectRatio: {
      types: [null, Number],
      default: null,
    },
    cover: {
      type: Boolean,
      default: false,
    },
    contain: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: null,
    },
    eager: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
    };
  },
  watch: {
    '$attrs.src'() {
      this.error = false;
    },
  },
  computed: {
    webp() {
      if (this.src.includes('/storage/') && !['svg', 'webp'].includes(this.src.split('.').slice(-1)[0]))
        return this.src + '.webp';
    },
    size() {
      let sizes = [];
      if (this.width) {
        sizes.push(this.width);
      }
      if (this.height) {
        sizes.push(this.height);
      }
      if (sizes.length === 0) {
        return 24;
      }

      return Math.min(...sizes) * 0.5
    },
    style() {
      return Object.fromEntries([
        this.cover || this.contain ? [
          'object-fit',
          this.cover ? 'cover' : 'contain',
        ] : null,
        this.position ? [
          'object-position',
          this.position,
        ] : null,
        [
          'font-size',
          `${this.size}px`
        ]
      ].filter(i => i))
    },
    attributes() {
      return {
        ...this.$attrs,
        ...(this.width ? {
          width: this.width,
          maxWidth: this.width,
        } : {}),
        ...(this.height ? {
          height: this.height,
          maxHeight: this.height,
        } : {}),
        ...(this.height && this.width ? {
          aspectRatio: this.width / this.height,
        } : (this.aspectRatio ? {
          aspectRatio: this.aspectRatio,
          width: '100%',
        } : {}))
      }
    },
  },
}
</script>

<style lang="scss" >
  picture {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      object-fit: contain;
      max-width: 100%;
      width: 100%;
      height: 100%;
      text-indent: 100%;

      &:after {
        content: "";
        background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff6a3d' %3E%3Cpath d='M19.5564 5.33467L18.6661 4.44434L12.0009 11.1096L5.33564 4.44434L4.44531 5.33467L11.1105 11.9999L4.44531 18.6651L5.33564 19.5554L12.0009 12.8902L18.6661 19.5554L19.5564 18.6651L12.8912 11.9999L19.5564 5.33467Z'%3E%3C/path%3E%3C/svg%3E") no-repeat center / 1em !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
</style>