import $ from "jquery";
import '@fancyapps/ui';
import {Fancybox} from "@fancyapps/ui";
import {FormsAjax} from "../block/formsAjax";
import {initMask} from "./init-maskedinput";



function openModal(src) {
    new Fancybox(
        [
            {
                src: src,
                type: 'ajax',
                tpl: {
                    closeButton: false,
                },
            },
        ],
        {
            on: {
                done: (Fancybox, eventName) => {
                    new FormsAjax();
                },
            },
        }
    );
}


window.openModal = openModal;