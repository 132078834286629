<template>
  <div class="component:slider" :class="{shifting, dragging, tapped}">
    <div class="component:slider--wrap">
      <div class="component:slider--slides" ref="wrap" :style="styles">
        <slot v-bind="{onClick}"/>
      </div>
    </div>
    <div class="component:slider--dots">
      <div v-for="dot in dots" :key="dot" class="component:slider--dot" :class="{active: dot === index}" @click="toSlide(dot)">
        <slot name="dot"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "slider-component",
  props: {
    useSSR: {
      type: Boolean,
      default: false,
    },
    threshold: {
      type: [String, Number],
      default: 100,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    play: {
      type: [Number, String],
      default: null,
    },
    sizeBorder: {
      type: Number,
      default: 15,
    }
  },
  provide() {
    return {
      slider: this,
    };
  },
  data() {
    return {
      index: 0,
      start: 0,
      dragPx: 0,
      dragging: false,
      tapped: false,
      shifting: false,
      wrap: null,
      observer: null,
      number: 0,
      items: [],
      isTouch: false,
      playTimeout: null,
      playLastIterate: null,
      playLastStop: null,
    }
  },
  computed: {
    dots() {
      return Object.keys(this.items).map(i => parseInt(i));
    },
    styles() {
      return {
        [this.vertical ? 'top' : 'left']: this.dragPx !== 0 ? `calc(${-this.index * 100}% + ${-this.dragPx}px)` : `${-this.index * 100}%`
      }
    },
    cleanThreshold() {
      return Number(this.threshold);
    },
    cleanPlay() {
      return Number(this.play);
    },
    lastIndex() {
      return this.items.length - 1;
    },
    size() {
      return this.wrap[`offset${this.vertical ? 'Height' : 'Width'}`];
    },
    sizeThreshold() {
      return this.sizeBorder * this.size / 100;
    },
  },
  watch: {
    cleanPlay: 'playCheck',
    dots: 'actualIndex',
  },
  methods: {
    playPause() {
      clearTimeout(this.playTimeout);
      this.playLastStop = Date.now() - this.playLastIterate;
    },
    playNext() {
      this.playStart(this.playLastStop);
    },
    playStart(minus = 0) {
      clearTimeout(this.playTimeout);
      this.playTimeout = setTimeout(this.next.bind(this), this.cleanPlay - minus);
    },
    playRestart() {
      this.playLastIterate = Date.now();
      this.playPause();
      this.playStart();
    },
    playCheck() {
      if (this.cleanPlay) {
        this.wrap.addEventListener('mouseenter', this.playPause);
        this.wrap.addEventListener('mouseleave', this.playNext);
        if (!this.useSSR) {
          document.addEventListener('visibilitychange', this.updateVisibility)
        }
        this.playLastIterate = Date.now();
        this.playStart();
      } else {
        this.playPause();
        this.wrap.removeEventListener('mouseenter', this.playPause);
        this.wrap.removeEventListener('mouseleave', this.playNext);
        if (!this.useSSR) {
          document.removeEventListener('visibilitychange', this.updateVisibility)
        }
      }
    },
    next() {
      this.playLastIterate = Date.now();
      this.shiftSlide(1);
      this.playStart();
    },
    onClick(e) {
      if (this.tapped) {
        e.preventDefault();
      }
      return !this.tapped;
    },
    toSlide(to) {
      this.shifting = true;
      this.index = to;
      this.playRestart();
    },
    shiftSlide(dir) {
      this.shifting = true;
      this.$nextTick(() => {
        let i = this.index + dir;
        if (this.loop) {
          if (i < 0) {
            i = this.lastIndex;
          } else if (i > this.lastIndex) {
            i = 0;
          }
        } else {
          i = Math.max(0, Math.min(i, this.lastIndex));
        }

        this.index = i;

        if (dir !== 0) {
          this.playRestart();
        }
      });
    },
    actualIndex() {
      this.shifting = true;
      this.index = 0;
    },
    dragStart(e) {
      this.playPause();

      if (e.type === 'mousedown') {
        e.preventDefault();
      }

      this.dragging = true;
      this.isTouch = e.type === "touchstart";

      if (this.isTouch) {
        this.start = e.touches[0].clientX;
        this.wrap.addEventListener("touchmove", this.dragAction);
      } else {
        this.start = e.clientX;
        this.wrap.addEventListener("mousemove", this.dragAction);
        this.wrap.addEventListener("mouseout", this.dragEnd);
      }
    },
    dragUp(e) {
      this.dragEnd(e);
      setTimeout(() => this.tapped = false, 20);
      this.wrap.removeEventListener("mouseout", this.dragUp);
    },
    dragEnd(e) {
      this.playStart();

      let dir = (this.dragPx > this.cleanThreshold) - (this.dragPx < -this.cleanThreshold);
      if (dir !== 0) {
        e.preventDefault();
      }

      this.dragPx = 0;

      this.shiftSlide(dir);
      this.dragging = false;

      if (this.isTouch) {
        this.wrap.removeEventListener("touchmove", this.dragAction);
      } else {
        this.wrap.removeEventListener("mousemove", this.dragAction);
      }
    },
    attach(item) {
      this.items.push(item);
    },
    detach(item) {
      this.items = this.items.filter(i => i !== item);
    },
    dragAction(e) {
      if (Math.abs(this.dragPx) > 5) {
        this.tapped = true;
      }

      if (this.isTouch) {
        this.dragPx = this.start - e.touches[0].clientX;
      } else {
        this.dragPx = this.start - e.clientX;
      }

      if (this.index === 0) {
        if (this.dragPx < -this.sizeThreshold) {
          this.dragEnd(e);
          return;
        }
      }
      if (this.index === this.lastIndex) {
        if (this.dragPx > this.sizeThreshold) {
          this.dragEnd(e);
        }
      }
    },
    checkIndex() {
      this.shifting = false;
    },
    updateVisibility() {
      if (!this.useSSR) {
        if (this.cleanPlay) {
          if (document.hidden) {
            this.playPause();
          } else {
            this.playNext();
          }
        }
      }
    },
  },
  mounted() {
    this.wrap = this.$refs.wrap;
    //this.observer = new MutationObserver(() => this.number = (this.number + 1) % 100);
    //this.observer.observe(
    //    this.wrap,
    //    {childList: true}
    //);
    if (!this.useSSR) {
      this.wrap.addEventListener('mousedown', this.dragStart);
      this.wrap.addEventListener('mouseup', this.dragUp);
      this.wrap.addEventListener('touchstart', this.dragStart);
      this.wrap.addEventListener('touchend', this.dragUp);
      this.wrap.addEventListener('transitionend', this.checkIndex, {passive: true});
      this.playCheck();
    }
  },
  beforeDestroy() {
    //this.observer.disconnect();
    if (!this.useSSR) {
      this.wrap.removeEventListener('mousedown', this.dragStart);
      this.wrap.removeEventListener('mouseup', this.dragUp);
      this.wrap.removeEventListener('touchstart', this.dragStart);
      this.wrap.removeEventListener('touchend', this.dragUp);
      this.wrap.removeEventListener('transitionend', this.checkIndex);
      this.wrap.removeEventListener("touchmove", this.dragAction);
      this.wrap.removeEventListener("mousemove", this.dragAction);
      this.wrap.removeEventListener("mouseleave", this.dragEnd);
      this.wrap.removeEventListener('mouseenter', this.playPause);
      this.wrap.removeEventListener('mouseleave', this.playNext);
      this.playPause();
      document.removeEventListener('visibilitychange', this.updateVisibility)
    }
  }
}
</script>