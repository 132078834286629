import $ from "jquery";
import '@fancyapps/ui';
import {Fancybox} from "@fancyapps/ui";


export class FormsAjax {
    constructor() {

        $('.ajax_js_form_callback').submit(function (event) {
            event.preventDefault();

            var form = $(this)[0]; // Получаем объект формы
            var formData = new FormData(form); // Создаем объект FormData и передаем форму

            var nameField = $(this).find('#feedback-name'); // Находим поле имени внутри текущей формы
            var phoneField = $(this).find('#feedback-phone'); // Находим поле телефона внутри текущей формы

            // Проверка имени
            if (nameField.val() === '') {
                nameField.parent().addClass('has-error');
            } else {
                nameField.parent().removeClass('has-error');
            }

            // Проверка телефона
            if (phoneField.val() === '') {
                phoneField.parent().addClass('has-error');
            } else {
                phoneField.parent().removeClass('has-error');
            }

            if (!nameField.parent().hasClass('has-error') && !phoneField.parent().hasClass('has-error')) {
                console.log(formData);
                $.ajax({
                    url: $(this).attr('action'), // Получаем URL из атрибута action текущей формы
                    //dataType: 'json',
                    data: formData,
                    type: "POST",
                    processData: false,
                    contentType: false,

                    success: function (response) {
                        console.log('Отправлено');
                        Fancybox.close();
                        $('#succers_alert').modal('show');
                        setTimeout(function() {
                            $('#succers_alert').modal('hide');
                        }, 2000); // 2000 миллисекунд = 2 секунды
                    }
                });
            }
        });

        $('.ajax_js_form_subscribe').submit(function (event) {
            event.preventDefault();

            var form = $(this)[0]; // Получаем объект формы
            var formData = new FormData(form); // Создаем объект FormData и передаем форму
            var emailField = $('#subscribe-email-modal');
            // Проверка введен ли email
            if (emailField.val() === '') {
                emailField.parent().addClass('has-error');
            } else {
                emailField.parent().removeClass('has-error');
            }
            if (!emailField.parent().hasClass('has-error')) {
                $.ajax({
                    url: $('.ajax_js_form_subscribe').attr('action'), // Получаем URL из атрибута action формы
                    //dataType: 'json',
                    data: formData,
                    type: "POST",
                    processData: false,
                    contentType: false,

                    success: function (response) {
                        $('.ajax_js_form_subscribe')[0].reset();
                        Fancybox.close();
                        $('#succers_alert').modal('show');
                        setTimeout(function() {
                            $('#succers_alert').modal('hide');
                        }, 2000); // 2000 миллисекунд = 2 секунды
                    }
                });
            }
        });

        $('.ajax_auth').submit(function (e) {
            e.preventDefault();

            var form = $(this)[0]; // Получаем объект формы
            var formData = new FormData(form); // Создаем объект FormData и передаем форму

            $.ajax({
                url: $('.ajax_auth').attr('action'), // Получаем URL из атрибута action формы
                data: formData,
                type: "POST",
                processData: false,
                contentType: false,
                dataType: 'json', // Указываем, что ожидаем JSON в ответе

                success: function (response) {
                    console.log('Отправил');

                    // Обрабатываем JSON-ответ
                    if (response && response.MESSAGES) {
                        // Выводим сообщения об ошибках
                        for (var i = 0; i < response.MESSAGES.length; i++) {
                            console.log(response.MESSAGES[i]);
                            // Показываем help-block
                            $('#password-error-container').addClass('has-error');
                        }

                    } else {

                    }
                    if (response.STATUS == 'OK') {
                        console.log('Успешная авторизация');
                        // Вывод оповещения
                        $(".alert-success").show();
                        // Перезагрузка страницы
                        setTimeout(function () {
                            window.location = window.location;
                        }, 1500); // 1500 миллисекунд = 1.5 секунды
                        // В случае успешной авторизации
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.error('Ошибка при выполнении AJAX-запроса: ' + textStatus, errorThrown);
                }
            });
        });


        $('.ajax_js_form_reviews').submit(function (event) {
                event.preventDefault();

                var form = $(this)[0]; // Получаем объект формы
                var formData = new FormData(form); // Создаем объект FormData и передаем форму
                var emailField = $('#subscribe-email-modal');
                // Проверка введен ли email
                if (emailField.val() === '') {
                    emailField.parent().addClass('has-error');
                } else {
                    emailField.parent().removeClass('has-error');
                }
                if (!emailField.parent().hasClass('has-error')) {
                    $.ajax({
                        url: $('.ajax_js_form_reviews').attr('action'), // Получаем URL из атрибута action формы
                        //dataType: 'json',
                        data: formData,
                        type: "POST",
                        processData: false,
                        contentType: false,

                        success: function (response) {
                            $('.ajax_js_form_reviews')[0].reset();
                            Fancybox.close();

                        }
                    });

                }
            }
        );
    }
}

document.addEventListener('DOMContentLoaded', () => {
    new FormsAjax();
});