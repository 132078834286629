import '@fancyapps/ui';
import {Fancybox} from "@fancyapps/ui";

export class CraftForms {
    action = null;
    method = null;
    form = null;

    constructor(selector) {
        this.form = document.querySelector(selector);

        if (!this.form) {
            //console.log(`Форма ${selector} не найдена`);
            return false;
        }

        this.form.onsubmit = this.attachEvent.bind(this);
        this.inputHandler();
        this.buttonClearInput();
    }

    buttonClearInput() {
        let iconsClear = this.form.querySelectorAll('.js-clear-input');
        if (!iconsClear) {
            return false;
        }

        iconsClear.forEach(iconElement => {
            iconElement.addEventListener('click', event => {
                event.preventDefault();

                let self = event.target;
                let parentElement = self.parentNode.parentNode;

                let inputs = parentElement.querySelectorAll('input[type="text"]');
                if (!inputs) {
                    return false;
                }

                inputs.forEach(el => {
                    el.value = "";
                });

            });
        })
    }

    inputHandler() {

        let inputs = this.form.querySelectorAll('input[type="text"], textarea');
        if (!inputs) {
            return false;
        }

        inputs.forEach(element => {
            element.addEventListener('focus', function (event) {

                let element = event.target;
                if (element.classList.contains('has-error')) {
                    element.classList.remove('has-error')
                }

                let field = element.parentNode.parentNode;
                if (!field) {
                    return false;
                }

                let errorInformer = field.querySelector('.site-form-error');
                if (!errorInformer) {
                    return false;
                }

                errorInformer.innerHTML = '';
            });
        });
    }


    attachEvent(event) {
        event.preventDefault();

        // this.form = event.target;

        let formData = new FormData(this.form);
        this.action = this.form.getAttribute('action');
        this.method = this.form.getAttribute('method');

        fetch(this.action, {
            method: this.method,
            body: formData
        })
            .then(response => response.json())
            .then(result => {

                let {code} = result;
                let {data} = result;

                this.resetErrors();

                // bad request
                if (code === 400) {
                    let {errors} = data;
                    this.errors(errors);
                }

                // success send form
                if (code === 200) {
                    this.resetForm();
                    this.success();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    errors(errors) {
        Object.keys(errors).map((propertyCode) => {


            let field = this.form.querySelector(`#field-${propertyCode}`);
            if (!field) {
                return false;
            }

            let errorElement = field.querySelector('.site-form-error');
            if (!errorElement) {
                return false;
            }

            errorElement.innerHTML = errors[propertyCode];


            let _input = field.querySelector('.site-form-input');
            if (!_input) {
                return false;
            }

            _input.classList.add('has-error');

        });
    }

    success() {

        let url = new URL(this.action);
        url.searchParams.append('action', 'getSuccess');
        new Fancybox([
            {
                src: url,
                type: "ajax",
            },
        ]);
    }

    resetForm() {
        // this.form.querySelectorAll("input[type=file]").forEach(el => {
        //
        //     el.value = null;
        //
        //     const e = new Event("change");
        //     el.dispatchEvent(e);
        // });

        this.form.reset();
    }

    resetErrors() {
        // let formCommonErrorElementList = this.form.querySelectorAll('.common-form-element');
        let formErrorElementList = this.form.querySelectorAll('.site-form-error');

        // if (formCommonErrorElementList) {
        //     formCommonErrorElementList.forEach(el => {
        //         el.classList.remove('has-error');
        //     });
        // }

        if (formErrorElementList) {
            formErrorElementList.forEach(el => {
                el.innerHTML = '';
            });
        }
    }
}