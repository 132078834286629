import Swiper from 'swiper';
import {Navigation, Pagination, Thumbs} from 'swiper/modules';




document.addEventListener('DOMContentLoaded', () => {
    // Слайдер на главной
    var swiper = new Swiper(".homepage-slider .swiper-container", {
        direction: 'horizontal',
        loop: false,
        speed: 3000,
        spaceBetween: 0,
        autoplay: false,

        modules: [Navigation, Pagination],
        navigation: {
            nextEl: 'section.homepage-slider .swiper-button-next',
            prevEl: 'section.homepage-slider .swiper-button-prev',
        },
        pagination: {
            el : '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            dynamicBullets: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            480: {
                slidesPerView: 1,
                spaceBetween: 10,
            }
        },
        preloadImages: true,
    });

    // Слайдер в новостях
    var swiperNews = new Swiper(".news-gallery .news-images", {
        direction: 'horizontal',
        loop: false,
        speed: 3000,
        spaceBetween: 0,
        autoplay: false,

        modules: [Navigation, Pagination],
        navigation: {
            nextEl: '.page-news-view .swiper-button-next',
            prevEl: '.page-news-view .swiper-button-prev',
        },
        pagination: {
            el : '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            dynamicBullets: true,
        },
        breakpoints: {
            992: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            480: {
                slidesPerView: 1,
                spaceBetween: 10,
            }
        },
        preloadImages: true,
    });

    // Слайдер в новостях мини
    var swiperThumbs = new Swiper(".news-gallery .news-thumbs", {
        direction: 'horizontal',
        loop: false,
        speed: 3000,
        spaceBetween: 0,
        autoplay: false,

        modules: [Navigation, Pagination],
        navigation: {
            nextEl: '.page-news-view .swiper-button-next',
            prevEl: '.page-news-view .swiper-button-prev',
        },
        pagination: {
            el : '.swiper-pagination',
            type: 'bullets',
            clickable: true,
            dynamicBullets: true,
        },
        linkedSwipers: [swiperNews],
        breakpoints: {
            992: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 1,
                spaceBetween: 10,
            },
            480: {
                slidesPerView: 1,
                spaceBetween: 10,
            }
        },
        preloadImages: true,
        slideToClickedSlide: true,

    });

// Связываем событие "slideChange" в обоих слайдерах
    swiperNews.on('slideChange', function () {
        swiperThumbs.slideTo(swiperNews.realIndex); // Переключаем мини-слайдер к соответствующему слайду в основном слайдере
    });

    swiperThumbs.on('slideChange', function () {
        swiperNews.slideTo(swiperThumbs.realIndex); // Переключаем основной слайдер к соответствующему слайду в мини-слайдере
    });

    var swiper1 = new Swiper(".product-thumbs", {
        direction: 'horizontal',
        slidesPerView: 'auto',
        spaceBetween: 10,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        modules: [Navigation, Pagination],
        navigation: {
            nextEl: ".product-thumbs .slider-btn-next",
            prevEl: ".product-thumbs .slider-btn-prev",
        },
        breakpoints: {
            // настройки для ширины экрана меньше 500px
            500: {
                direction: 'vertical',
                slidesPerView: '3', // позволяет автоматически определять количество видимых слайдов
                spaceBetween: 10,
            }
        }
    });

    var swiper2 = new Swiper(".product-images", {
        direction: 'horizontal',
        slidesPerView: 1,
        spaceBetween: 30,
        breakpoints: {
            // настройки для ширины экрана меньше 500px
            500: {
                direction: 'vertical',
                slidesPerView: '1', // позволяет автоматически определять количество видимых слайдов
                spaceBetween: 10,
            }
        }
    });

    swiper1.on('slideChange', function () {
        var activeIndex = swiper1.activeIndex;
        swiper2.slideTo(activeIndex);
        updateActiveClass(activeIndex);
    });

    swiper2.on('slideChange', function () {
        var activeIndex = swiper2.activeIndex;
        swiper1.slideTo(activeIndex);
        updateActiveClass(activeIndex);
    });

    swiper1.slides.forEach(function (slide, index) {
        slide.addEventListener('click', function () {
            swiper1.slideTo(index);
        });
    });

    swiper2.slides.forEach(function (slide, index) {
        slide.addEventListener('click', function () {
            swiper2.slideTo(index);
        });
    });

    function updateActiveClass(activeIndex) {
        swiper1.slides.forEach(function (slide) {
            slide.classList.remove('swiper-slide-active');
        });
        swiper1.slides[activeIndex].classList.add('swiper-slide-active');
    }
    // Находим все элементы с классом product-thumbs__img
    var thumbs = document.querySelectorAll('.product-thumbs__img');

// Добавляем обработчик события для каждой миниатюры
    thumbs.forEach(function (thumb, index) {
        thumb.addEventListener('click', function () {
            swiper1.slideTo(index); // Переключаем swiper1 на выбранный слайд
            swiper2.slideTo(index); // Переключаем swiper2 на выбранный слайд
            updateActiveClass(index); // Обновляем активный класс для подсветки
        });
    });




// Инициализация Swiper


    function initSwiper() {
        // Проверка ширины экрана
        if (window.innerWidth < 500) {
            // Инициализация Swiper, если ширина экрана меньше 500px
            var home_sections = new Swiper(".swiper--hide-desktop .swiper-container", {
                direction: 'horizontal',
                loop: false,
                speed: 3000,
                spaceBetween: 0,
                autoplay: false,
                modules: [Pagination],
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                    dynamicBullets: true,
                },
                preloadImages: true,
            });
        }
    }

// Вызов при загрузке страницы
    initSwiper();

// Вызов при изменении размера окна
    window.addEventListener('resize', initSwiper);
});

